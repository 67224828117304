import { connect } from "react-redux"

// material-ui
import logo from 'assets/images/alhambra-horizontal.svg';

// ==============================|| LOGO SVG ||============================== //

const LogoHorizontal = ({ user, props }) => {
    return <img src={user.establishment.company.logo} alt="Alhambra" width="130" />;
};

const mapStateToProps = (state, props) => {
    return {
        user: state.authUser.user,
        props: props,
    }
}

export default connect(mapStateToProps, {})(LogoHorizontal)
