export const endpointApiUrl = `https://api.eljuri.com`
// export const endpointApiUrl = `http://127.0.0.1:8000`

// export const tokenAPI = "9dced33cf4e42d3328c708d0d172f32522e5917e"

export const endpointApi = {
   url: endpointApiUrl,
   endpoint: {
      province: `${endpointApiUrl}/province/`,
      user: `${endpointApiUrl}/user/`,
      login: `${endpointApiUrl}/login/`,
      subscription: `${endpointApiUrl}/subscription/`,
   },
}

export const groups = {
   seller: 1,
   sales_coordinator: 2,
   approver: 3,
}
