import React from 'react'
import { useDispatch, connect, useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import { useNavigate } from 'react-router';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'redux/theme/constant';
import { SET_MENU } from 'redux/theme/actions';

// assets
import { IconChevronRight } from '@tabler/icons';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        // [theme.breakpoints.up('md')]: {
        //     marginLeft: -(drawerWidth - 20),
        //     width: `calc(100% - ${drawerWidth}px)`
        // },
        // [theme.breakpoints.down('md')]: {
        //     marginLeft: '20px',
        //     width: `calc(100% - ${drawerWidth}px)`,
        //     padding: '16px'
        // },
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: '10px',
        //     width: `calc(100% - ${drawerWidth}px)`,
        //     padding: '16px',
        //     marginRight: '10px'
        // }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        // width: `calc(100% - ${drawerWidth}px)`,
        // [theme.breakpoints.down('md')]: {
        //     marginLeft: '20px'
        // },
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: '10px'
        // }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({ user, props }) => {
    const handle = useFullScreenHandle();
    const theme = useTheme();
    const navigate = useNavigate();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    React.useEffect(() => {
        if (user === null) {
            return navigate("/login")
        }
    }, [user]);

    return user ? (
        <FullScreen handle={handle}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                {/* header */}
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                        bgcolor: theme.palette.background.default,
                        transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                    }}
                    style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
                >
                    <Toolbar>
                        <Header fullScreen={handle} handleLeftDrawerToggle={handleLeftDrawerToggle} />
                    </Toolbar>
                </AppBar>

                {/* drawer */}

                {/* main content */}
                <Main theme={theme} open={isMobile ? false : leftDrawerOpened} className={isMobile ? "main-mobile-alhambra" : ""}>
                    <Outlet />
                </Main>
                <Customization />
            </Box>
        </FullScreen>
    ) : (<></>);
};

const mapStateToProps = (state, props) => {
    return {
        user: state.authUser.user,
        props: props,
    }
}

export default connect(mapStateToProps, {})(MainLayout)